import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { RecaptchaComponent } from 'ng-recaptcha';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import 'hammerjs';


if (environment.production) {
  enableProdMode();
}

RecaptchaComponent.prototype.ngOnDestroy = function () {
  // if (this.subscription) {
  //   this.subscription.unsubscribe();
  // }
};

function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}


